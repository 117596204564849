//Componentes generales
import React, { useEffect, useState } from "react";

import moment from "moment";

//Permisos
import {
  EDITAR_REQUERIMIENTO,
  MODIFICAR_ESTADO_REQUERIMIENTO,
  VER_TODOS_REQUERIMIENTOS,
  REENVIO_MOSCUZZA,
} from "../../utils/permisos";

//Tipos de Prioridades
import { PRIORITYOUT } from "../../utils/priority";
import Swal from "sweetalert2";

const RenderRow = ({
  currentUser,
  index,
  style,
  listTDoc,
  filter,
  checkPermisos,
  cambiarEstado,
  editar,
  verDetalles,
  statesNEviables,
  sendToAPI,
  guardarEstado,
  cerrar,
}) => {
  const [currentLista, setCurrentLista] = useState(listTDoc ? listTDoc : []);

  const saveReq = async (r) => {
    try {
      const resp = await guardarEstado(r, r.state, r.observAut);
  
      if (resp.data && (resp.data.enviadoState === true || resp.data.state == "PENDIENTE" || resp.data.state == "PENDIENTE (REVISIÓN MOSCUZZA)")) {
        Swal.fire(
          "Estado: " + r.state,
          "El Estado fue Enviado Correctamente <br> <strong>" +
            resp.message +
            " </strong> ",
          "success"
        );
      } else if(resp.data && resp.data.enviadoState === false){
        Swal.fire({
          icon: "error",
          title: "Estado: " + r.state,
          text: "El Estado no fue Enviado. Por favor, vuelve a intentarlo más tarde. ",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Estado: " + r.state,
          text: resp,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al enviar el estado",
        text: "Hubo un problema al intentar enviar el estado. Intenta nuevamente más tarde.",
      });
      console.error("Error en la solicitud de guardar estado:", error);
    } finally {
      cerrar(null);
    }
  };
  

  /*
        if(checkPermisos(VER_TODOS_REQUERIMIENTOS)){
            currentLista = listTDoc;
        }else{
            currentLista = listTDoc.filter(r => r.userName.includes(currentUser.userName));
        }*/

  if (currentLista && currentLista.length > 0) {
    if (filter === "") {
      return (
        <div style={style}>
          <div className="container-r-list-item">
            <div className="container-r-list-item-header">
              <div className="r-list-item-header-clave">
                <div>
                  {currentLista[index].tDocCodFor +
                    " - " +
                    currentLista[index].tDocNroFor}
                </div>
                {currentLista[index].apl &&
                currentLista[index].apl.length > 0 ? (
                  <div style={{ marginTop: "-8px", marginBottom: "10px" }}>
                    <div style={{ display: "inline", fontSize: "22px" }}>
                      Aplica sobre:
                    </div>
                    {currentLista[index].apl.map((item, index) => (
                      <div
                        className={"r-list-item-details-title-apl"}
                        key={index}
                        onClick={() => {
                          localStorage.setItem(
                            "comprobante-apl",
                            JSON.stringify(item.replace(/ /g, " - "))
                          );
                          window.open(window.location.href, "_blank");
                        }}
                      >
                        {item.replace(/ /g, " - ")}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="r-list-item-header-title">
                {currentLista[index].title}
              </div>
              <div className="margin-right-r">
                <div
                  className={
                    currentLista[index].priority === 0
                      ? "r-list-item-header-priority-media"
                      : currentLista[index].priority === 1
                      ? "r-list-item-header-priority-alta"
                      : "r-list-item-header-priority-urgente"
                  }
                >
                  {PRIORITYOUT[currentLista[index].priority]}
                </div>
              </div>
            </div>
            <div className="container-r-list-item-details">
              <div className="r-list-item-details-clave">
                {currentLista[index].state}
              </div>
              <div>
                <div className="r-list-item-details-title">
                  {currentLista[index].tDocCodFor === "REQ"
                    ? moment.utc(currentLista[index].fecAlt).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : moment.utc(currentLista[index].fecAltERP).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                </div>
                <div className="r-list-item-details-title">
                  Fecha de Movimiento:{" "}
                  {moment.utc(currentLista[index].fchMov).format(
                    "DD-MM-YYYY"
                  )}
                </div>
              </div>
              <div>
                <div className="r-list-item-details-title">
                  Responsable: {currentLista[index].tDocAbmResponsibleName}
                </div>
                <div
                  style={{ textAlign: "left" }}
                  className="r-list-item-details-title"
                >
                  Solicitante: {currentLista[index].tDocAbmApplicantName}
                </div>
              </div>
              <div>
                <div className="r-list-item-details-title">
                  Fecha de Entrega:{" "}
                  {moment.utc(currentLista[index].deliveryDate).format(
                    "DD-MM-YYYY"
                  )}
                </div>
                {currentLista[index].fecAut &&
                currentLista[index].state === "AUTORIZADO" ? (
                  <div className="r-list-item-details-title">
                    Fecha de Autorización:{" "}
                    {moment.utc(currentLista[index].fecAut).format("DD-MM-YYYY")}
                  </div>
                ) : null}
              </div>
              <div style={{ marginRight: "20px" }}>
                <div className="r-list-item-details-title">
                  Última modificación:{" "}
                  {moment.utc(currentLista[index].fecMod).format("DD-MM-YYYY") ===
                    moment.utc(currentLista[index].fecAlt).format("DD-MM-YYYY") &&
                  moment.utc(currentLista[index].fecModERP).format("DD-MM-YYYY")
                    ? moment.utc(currentLista[index].fecModERP).format("DD-MM-YYYY")
                    : moment.utc(currentLista[index].fecMod).format("DD-MM-YYYY")}
                </div>
                <div className="r-list-item-details-title">
                  Usuario: {currentLista[index].userName}
                </div>
              </div>
            </div>
            <div className="container-r-list-item-btn">
              {checkPermisos(MODIFICAR_ESTADO_REQUERIMIENTO) && (
                <div
                  className="r-list-item-btn-A"
                  onClick={() => cambiarEstado(currentLista[index])}
                >
                  Modificar Estado
                </div>
              )}
              {checkPermisos(EDITAR_REQUERIMIENTO) &&
                currentLista[index].state === "PENDIENTE" &&
                currentLista[index].enviado === false && (
                  <div
                    className="r-list-item-btn-A"
                    onClick={() => editar(currentLista[index])}
                  >
                    Editar
                  </div>
                )}
              <div
                className="r-list-item-btn-A"
                onClick={() => verDetalles(currentLista[index])}
              >
                Detalles
              </div>
            </div>
            {checkPermisos(REENVIO_MOSCUZZA) &&
              !statesNEviables.includes(currentLista[index].state) &&
              currentLista[index].enviado === false && (
                <div
                  className="container-resend"
                  onClick={() => sendToAPI(currentLista[index])}
                >
                  {"Enviar Requerimiento >>>"}
                </div>
              )}
            {checkPermisos(REENVIO_MOSCUZZA) &&
              !statesNEviables.includes(currentLista[index].state) &&
              currentLista[index].enviado === true &&
              currentLista[index].enviadoState === false && (
                <div
                  className="container-resend"
                  onClick={() => saveReq(currentLista[index])}
                >
                  {"Enviar Actualización de Estado >>>"}
                </div>
              )}
          </div>
        </div>
      );
    } else {
      const req_filters = currentLista.filter(
        (r) =>
          r.title.toLowerCase().includes(filter.toLowerCase()) ||
          (
            r.tDocCodFor.toLowerCase() +
            " - " +
            r.tDocNroFor.toString().toLowerCase()
          ).includes(filter.toLowerCase()) ||
          r.tDocNroFor.toString().toLowerCase().includes(filter.toLowerCase())
      );
      return (
        <div style={style}>
          <div className="container-r-list-item">
            <div className="container-r-list-item-header">
              <div className="r-list-item-header-clave">
                <div>
                  {req_filters[index].tDocCodFor +
                    " - " +
                    req_filters[index].tDocNroFor}
                </div>
                {req_filters[index].apl && req_filters[index].apl.length > 0 ? (
                  <div style={{ marginTop: "-8px", marginBottom: "10px" }}>
                    <div style={{ display: "inline", fontSize: "22px" }}>
                      Aplica sobre:
                    </div>
                    {req_filters[index].apl.map((item, index) => (
                      <div
                        className={"r-list-item-details-title-apl"}
                        key={index}
                        onClick={() => {
                          localStorage.setItem(
                            "comprobante-apl",
                            JSON.stringify(item.replace(/ /g, " - "))
                          );
                          window.open(window.location.href, "_blank");
                        }}
                      >
                        {item.replace(/ /g, " - ")}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="r-list-item-header-title">
                {req_filters[index].title}
              </div>
              <div className="margin-right-r">
                <div
                  className={
                    req_filters[index].priority === 0
                      ? "r-list-item-header-priority-media"
                      : req_filters[index].priority === 1
                      ? "r-list-item-header-priority-alta"
                      : "r-list-item-header-priority-urgente"
                  }
                >
                  {PRIORITYOUT[req_filters[index].priority]}
                </div>
              </div>
            </div>
            <div className="container-r-list-item-details">
              <div className="r-list-item-details-clave">
                {req_filters[index].state}
              </div>
              <div>
                <div className="r-list-item-details-title">
                  {req_filters[index].tDocCodFor === "REQ"
                    ? moment.utc(req_filters[index].fecAlt).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : moment.utc(req_filters[index].fecAltERP).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                </div>
                <div className="r-list-item-details-title">
                  Fecha de Movimiento:{" "}
                  {moment.utc(req_filters[index].fchMov).format(
                    "DD-MM-YYYY"
                  )}
                </div>
              </div>
              <div>
                <div className="r-list-item-details-title">
                  Responsable: {req_filters[index].tDocAbmResponsibleName}
                </div>
                <div
                  style={{ textAlign: "left" }}
                  className="r-list-item-details-title"
                >
                  Solicitante: {req_filters[index].tDocAbmApplicantName}
                </div>
              </div>
              <div>
                <div className="r-list-item-details-title">
                  Fecha de Entrega:{" "}
                  {moment.utc(req_filters[index].deliveryDate).format("DD-MM-YYYY")}
                </div>
                {req_filters[index].fecAut &&
                req_filters[index].state === "AUTORIZADO" ? (
                  <div className="r-list-item-details-title">
                    Fecha de Autorización:{" "}
                    {moment.utc(req_filters[index].fecAut).format("DD-MM-YYYY")}
                  </div>
                ) : null}
              </div>
              <div style={{ marginRight: "60px" }}>
                <div className="r-list-item-details-title">
                  Última modificación:{" "}
                  {moment.utc(req_filters[index].fecMod).format("DD-MM-YYYY") ===
                    moment.utc(req_filters[index].fecAlt).format("DD-MM-YYYY") &&
                  moment.utc(req_filters[index].fecModERP).format("DD-MM-YYYY")
                    ? moment.utc(req_filters[index].fecModERP).format("DD-MM-YYYY")
                    : moment.utc(req_filters[index].fecMod).format("DD-MM-YYYY")}
                </div>
                <div className="r-list-item-details-title">
                  Usuario: {req_filters[index].userName}
                </div>
              </div>
            </div>
            <div className="container-r-list-item-btn">
              {checkPermisos(MODIFICAR_ESTADO_REQUERIMIENTO) && (
                <div
                  className="r-list-item-btn-A"
                  onClick={() => cambiarEstado(req_filters[index])}
                >
                  Modificar Estado
                </div>
              )}
              {checkPermisos(EDITAR_REQUERIMIENTO) &&
                req_filters[index].state === "PENDIENTE" &&
                req_filters[index].enviado === false && (
                  <div
                    className="r-list-item-btn-A"
                    onClick={() => editar(req_filters[index])}
                  >
                    Editar
                  </div>
                )}
              <div
                className="r-list-item-btn-A"
                onClick={() => verDetalles(req_filters[index])}
              >
                Detalles
              </div>
            </div>
            {checkPermisos(REENVIO_MOSCUZZA) &&
              !statesNEviables.includes(req_filters[index].state) &&
              req_filters[index].enviado === false && (
                <div
                  className="container-resend"
                  onClick={() => sendToAPI(req_filters[index])}
                >
                  {"Enviar Requerimiento >>>"}
                </div>
              )}
            {checkPermisos(REENVIO_MOSCUZZA) &&
              !statesNEviables.includes(req_filters[index].state) &&
              req_filters[index].enviado === true &&
              req_filters[index].enviadoState === false && (
                <div
                  className="container-resend"
                  onClick={() => saveReq(req_filters[index])}
                >
                  {"Enviar Actualización de Estado >>>"}
                </div>
              )}
          </div>
        </div>
      );
    }
  }
};

export default RenderRow;
