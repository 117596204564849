//Componentes generales
import React, { useReducer, useContext, useEffect } from "react";
import controlReducer from "./controlReducer";
import controlContext from "./controlContext";
import moment from "moment";

//Axios
import clienteAxios from "../../config/axios";

//Rutas de api
import { getProductsByTDocNroFor } from "../../api/product";
import {
  getTDocByFilter,
  getTDocsByUserIdAndParams,
} from "../../api/transactionDocuments";
import { getUsersInGroupV } from "../../api/user";
import { getAbmApplicants } from "../../api/abmApplicant";
import { getAbmResponsibles } from "../../api/abmResponsible";
import { getAbmReqSector } from "../../api/abmReqSector";

//Socket
import { SocketContext } from "../socket/socket";

//Acciones
import {
  ACTUALIZAR_TDOC,
  AGREGAR_TDOC,
  MODIFICAR_FILTROS_ESTADOS,
  MODIFICAR_FILTROS_RANGO,
  INICIAR_FILTROS,
} from "./controlActions";

//Estados de autorización
import { STATES } from "../../utils/states";
import { filtrosToString } from "../../utils/funciones";

//Valores iniciales
const initialState = {
  listTDoc: null,
  filtrosRango: {
    fchMov: {
      desde: new Date(moment.utc(new Date()).subtract(360, "days")),
      hasta: new Date(moment.utc(new Date())),
    },
    deliveryDate: new Date(moment.utc(new Date()).add(30, "days")),
  },
  filtrosEstado: {
    codFor: [],
    state: [],
    priority: [0, 1, 2],
    userName: [],
    tDocAbmReqSector:[{}],
    tDocAbmApplicant: [{}],
    tDocAbmResponsible: [{}],
  },
  filtrosEstadoPredeterminado: null,
  cargando: false,
};

/* 
    State encargado de la gestión de filtros y listado de requerimientos. (No se encuentra tan en uso, en la mayoria fue reemplazado por controlGLB).
*/
const ControlState = (props) => {
  const server = useContext(SocketContext);

  /* 
        Disparadores que sincronizan la lista de requerimientos ante la creación de un requerimiento.
    */
  useEffect(() => {
    if (server) {
      //TDoc Nuevo
      server.on("addTDoc", (tDoc) => {
        console.log("RECIBO EL TDOC CREADO");
        dispatch({
          type: AGREGAR_TDOC,
          payload: tDoc,
        });
      });

      return () => server.off("addTDoc");
    }
  }, [server]);

  /* 
        Disparadores que sincronizan la lista de requerimientos ante la modificación de un requerimiento.
    */
  useEffect(() => {
    if (server) {
      //TDoc Modificado
      server.on("modTDoc", ({ tDoc }) => {
        dispatch({
          type: ACTUALIZAR_TDOC,
          payload: tDoc,
        });
      });

      return () => server.off("modTDoc");
    }
  }, [server]);

  const [state, dispatch] = useReducer(controlReducer, initialState);

  //Logica del control state;

  //Inicializa los filtros dependiendo del usuarios y del comprobante seleccionado
  const iniciarFiltros = async (
    activeWorkSpace,
    usuario = "",
    comprobanteSeleccionado
  ) => {
    try {
      console.log("Entra en inciar Filtros");
      //Paso todos los dates de los filtros rango a un valor valido ademas de transfor en cadena de string para pasar en peticion get.
      const filtrosString = filtrosToString(initialState.filtrosRango);

      //Relizo peticion con los parametros de rango y el usuario en secion
      const resp = await getTDocsByUserIdAndParams(usuario._id, filtrosString);

      //Obtengo los usuarios que puede ver el usuario mediante su campo groupV
      const respUsuarios = await getUsersInGroupV(usuario._id);
      //console.log(respUsuarios);
      //const usuarios = respUsuarios.data.data.map((u) => u.user.userName);
      //Creo un arreglo de userNames y luego si no se encuentra el usuario en sesion lo agrego
      const usuarios = [
        ...new Set(respUsuarios.data.data.map((u) => u.user.userName)),
      ];
      console.log(usuarios);
      if (!usuarios.includes(usuario.userName)) usuarios.push(usuario.userName);
      //Obtengo los Sectores de Requerimientos
      const respSectoresRequerimientos = await getAbmReqSector();
      const sectoresRequerimientos = respSectoresRequerimientos.data.data.map(
        (sR) => ({
          codSem: sR.codSem,
          descrp: sR.descrp,
        })
      );
      //Obtengo los solicitantes
      const respSolicitantes = await getAbmApplicants();
      const solicitantes = respSolicitantes.data.data.map((s) => ({
        idSql: s.idSql,
        name: s.name,
      }));
      //Obtengo los responsables
      const respResponsables = await getAbmResponsibles();
      const responsables = respResponsables.data.data.map((r) => ({
        idSql: r.idSql,
        name: r.name,
      }));

      let comprobantes = [];
      if (comprobanteSeleccionado == "REQ") {
        comprobantes = ["REQ", "NPI", "NPIP"];
      } else if (comprobanteSeleccionado == "OC") {
        comprobantes = ["OC", "OCP"];
      }

      let state = []
      if(activeWorkSpace.todos ==1){
        state = STATES
      }else if(activeWorkSpace.pendientes==1){
        state=[
          "PENDIENTE",
          "PENDIENTE (REVISIÓN MOSCUZZA)"]
      }else if(activeWorkSpace.autorizados==1){
        state=[
          "AUTORIZADO"]
      }

      const filtrosEstado = {
        ...initialState.filtrosEstado,
        codFor: comprobantes,
        state: state,
        userName: usuarios,
        tDocAbmReqSector:sectoresRequerimientos,
        tDocAbmApplicant: solicitantes,
        tDocAbmResponsible: responsables,
      };

      dispatch({
        type: INICIAR_FILTROS,
        payload: {
          tDocs: resp.data.data,
          filtrosEstado: filtrosEstado,
        },
      });
    } catch (error) {
      console.log(error);
      console.log("Error a la hora de iniciar filtros");
    }
  };

  //Modifica los filtros de rango y trae los tDocs acorde a los parametros enviados
  const modificarFiltrosRango = async (userId, filtrosRango) => {
    const filtrosString = filtrosToString(filtrosRango);

    const resp = await getTDocsByUserIdAndParams(userId, filtrosString); //clienteAxios.post('/transactionDocument/filter', filtrosRango);

    const listaRequerimientos = resp.data.data;

    dispatch({
      type: MODIFICAR_FILTROS_RANGO,
      payload: { filtrosRango, listaRequerimientos },
    });
  };

  //Modifica los filtros de estado
  const modificarFiltrosEstado = (filtrosEstados) => {
    dispatch({
      type: MODIFICAR_FILTROS_ESTADOS,
      payload: filtrosEstados,
    });
  };

  //Filtra una lista de tDoc de acuerdo a los filtros

  const filtrar = (lista, filtrosEstado) => {
    if (
      !lista ||
      lista === undefined ||
      state.filtrosEstado.userName === undefined
    ) return [];

    const listaFiltrada = lista.filter(
      (item) =>
        filtrosEstado.state.includes(item.state) &&
        filtrosEstado.priority.includes(item.priority) &&
        filtrosEstado.userName
          .map((name) => name.toUpperCase())
          .includes(item.userName.toUpperCase()) &&
          filtrosEstado.tDocAbmReqSector
            .map((reqSector) => reqSector.codSem)
            .includes(item.tDocAbmReqSectorCodsem) &&
        filtrosEstado.tDocAbmApplicant
          .map((applicant) => applicant.idSql)
          .includes(item.tDocAbmApplicantIdSql) &&
        filtrosEstado.tDocAbmResponsible
          .map((responsible) => responsible.idSql)
          .includes(item.tDocAbmResponsibleIdSql) &&
        filtrosEstado.codFor.includes(item.tDocCodFor)
    );

    return listaFiltrada;
  };

  //Auxiliar
  const getProductsByNroFor = async (tDocCodFor, tDocNroFor) => {
    try {
      const resp = await getProductsByTDocNroFor(tDocCodFor, tDocNroFor); //clienteAxios.get(`/productBytDocNroFor/${tDocCodFor}/${tDocNroFor}`);
      return resp.data.data;
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <controlContext.Provider
      value={{
        listTDoc: state.listTDoc,
        cargando: state.cargando,
        filtrosEstado: state.filtrosEstado,
        filtrosRango: state.filtrosRango,
        iniciarFiltros,
        modificarFiltrosEstado,
        modificarFiltrosRango,
        filtrar,
        getProductsByNroFor,
      }}
    >
      {props.children}
    </controlContext.Provider>
  );
};

export default ControlState;
