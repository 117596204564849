//Componentes generales
import React, { useState, useEffect } from "react";
import moment from "moment";

//Tipos de Prioridades
import { PRIORITY, PRIORITYIN } from "../../utils/priority";

//Permisos
import { EDITAR_NOTAS_PRIVADAS } from "../../utils/permisos";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faBox,
  faRotateRight,
  faFilePdf,
  faDownload,
  faPen,
  faRectangleXmark,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";

//Estilos
import "./ReqInfo.css";

/* 
    Componente encargado de mostrar el detalle del requerimiento.
      Donde item es el requerimiento seleccionado.
      files son los archivos adjuntados al requerimiento.
      cerrar es la función encargada de ocultar el dialogo.
      verDetalle es la función del context que recupera los productos del requerimiento.
      repetir es una función que llama al componente que corresponda.
      generaPDF es una función que llama al componente que corresponda.
      abrirURL es la función encargada de abrir en una nueva ventana el archivo seleccionado.
      guardarModificaciones es la función del context que guarda el requerimiento en el caso de haber modificaciones.
      usuario es el usuario en sesión.

*/
function ReqInfo({
  item = {},
  files,
  comprobantePdf,
  cerrar,
  verDetalle,
  repetir,
  generaPDF,
  abrirURL,
  guardarModificaciones,
  usuario,
}) {
  //state de productos.
  const [products, setProducts] = useState([]);
  //state de requerimiento.
  const [requerimiento, setRequerimiento] = useState(item);
  //state del importe sin impuestos.
  const [importeSinImpuestos, setImporteSinImpuestos] = useState(0);
  //state para edición de nota.
  const [editNota, setEditNota] = useState(false);
  //state para edición de nota privada.
  const [editNotaReservada, setEditNotaReservada] = useState(false);
  //state para edición de prioridad.
  const [editPrioridad, setEditPrioridad] = useState(false);
  //state para indicar nota ya editada.
  const [editadoNota, setEditadoNota] = useState(false);
  //state para indicar nota reservada ya editada.
  const [editadoNotaReservada, setEditadoNotaReservada] = useState(false);

  /*
      Al renderizar el componente, recupera los productos del requerimiento seleccionado.
  */

  useEffect(() => {
    async function fp() {
      try {
        const p = await verDetalle(item.tDocCodFor, item.tDocNroFor);
        setProducts(p);
        let importeTotal = 0;
        // Recorrer el array y sumar los precios
        p.forEach((product) => {
          if (product.bonus1 && product.bonus1 !== 0) {
            importeTotal +=
              product.quantity *
              (product.price - product.price * (-product.bonus1 / 100));
          } else {
            importeTotal += product.price * product.quantity;
          }
        });
        setImporteSinImpuestos(importeTotal);
      } catch (error) {
        console.log("error");
      }
    }
    fp();
  }, []);

  /*
      Disparador que se activa al detectar algun cambio en el item. Setea el requerimiento con el item que fue modificado.
  */
  useEffect(() => {
    setRequerimiento(item);
  }, [item]);

  /*
      Manejador que descargar/abre todos los archivos adjuntados al requerimiento. 
  */
  const handleDownloadAll = () => {
    files.map((file) => abrirURL(file.url));
  };

  /* 
      Manejador que determina si habilitar o no la edición del campo Nota.
  */
  const handleEditNota = () => {
    setEditNota(!editNota);
  };

  /* 
      Manejador que determina si habilitar o no la edición del campo Nota Reservada.
  */
  const handleEditNotaReservada = () => {
    setEditNotaReservada(!editNotaReservada);
  };

  /* 
      Manejador que determina si habilitar o no la edición de la prioridad.
  */
  const handleEditPrioridad = () => {
    setEditPrioridad(!editPrioridad);
  };

  /* 
      Manejador ante cambio en casilla de Nota.
      Donde valor es lo ingresado por el usuario.
  */
  const handleModificaNota = (valor) => {
    setRequerimiento({ ...requerimiento, notePubl: valor });
    setEditadoNota(true);
  };

  /* 
      Manejador ante cambio en casilla de Nota Reservada.
      Donde valor es lo ingresado por el usuario.
  */
  const handleModificaNotaReservada = (valor) => {
    setRequerimiento({ ...requerimiento, notePriv: valor });
    setEditadoNotaReservada(true);
  };

  /* 
        Manejador ante cambio en la prioridad.
        Donde valor es lo seleccionado por el usuario.
  */
  const handleModificaPrioridad = async (valor) => {
    const req_temp = { ...requerimiento, priority: valor };
    setEditPrioridad(false);
    setEditNotaReservada(false);
    setEditNota(false);
    const resp = await guardarModificaciones(req_temp, { priority: valor });

    setRequerimiento(resp.data);
  };

  /* 
      Guarda el requerimiento mediante función del context.
  */
  const saveChangesNota = async (valor) => {
    setEditNota(false);
    setEditPrioridad(false);

    const obj = { notePubl: requerimiento.notePubl };
    const resp = await guardarModificaciones(requerimiento, obj);
    setRequerimiento(resp.data);
  };

  const saveChangesNotaReservada = async (valor) => {
    setEditNotaReservada(false);
    setEditPrioridad(false);

    const obj = { notePriv: requerimiento.notePriv };
    const resp = await guardarModificaciones(requerimiento, obj);
    setRequerimiento(resp.data);
  };

  return (
    <div className="container-gr-req">
      <div>
        <div className="container-r-list-item-header-info">
          <div style={{ minWidth: "460px" }}>
            <div className="label-req" style={{ display: "inline" }}>
              {requerimiento.tDocCodFor + " - " + requerimiento.tDocNroFor}
            </div>
            {!requerimiento.tDocCodFor.includes("REQ") ? (
              <div
                style={{ display: "inline" }}
                className="rq-button-bottom button-comprobante-pdf"
                onClick={() => abrirURL(comprobantePdf[0].url)}
              >
                <FontAwesomeIcon icon={faFilePdf} /> Ver Comprobante
              </div>
            ) : null}
          </div>
          <div className="r-list-item-header-title">{requerimiento.title}</div>
          {/* <div className={requerimiento.priority === 0 ? 'r-list-item-header-priority-media margin-right-r' : requerimiento.priority === 1 ? 'r-list-item-header-priority-alta margin-right-r' : 'r-list-item-header-priority-urgente margin-right-r'}>{PRIORITYOUT[requerimiento.priority]}</div> */}
          <div className="container-prioritys-gral">
            <div
              className={
                requerimiento.priority === 2
                  ? "r-list-item-header-priority-urgente  margin-right-r"
                  : requerimiento.priority === 1
                  ? "r-list-item-header-priority-alta  margin-right-r"
                  : "r-list-item-header-priority-media  margin-right-r"
              }
              onClick={handleEditPrioridad}
            >
              {PRIORITY[requerimiento.priority ? requerimiento.priority : 0]}
            </div>
            {editPrioridad && (
              <div className="options-container-reqinfo">
                <div className="options-container-fr-back">
                  {PRIORITY.map((prior) => {
                    return (
                      <div
                        key={prior}
                        className={
                          PRIORITYIN[prior] === 2
                            ? "r-list-item-header-priority-urgente"
                            : PRIORITYIN[prior] === 1
                            ? "r-list-item-header-priority-alta"
                            : "r-list-item-header-priority-media"
                        }
                        value={prior}
                        onClick={() =>
                          handleModificaPrioridad(PRIORITYIN[prior])
                        }
                      >
                        {prior}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={faXmark}
            onClick={() => cerrar(null)}
          />
        </div>
        {requerimiento.apl && requerimiento.apl.length > 0 ? (
          <div>
            <div className="apl">
              <div
                style={{ fontWeight: "600", padding: "5px", fontSize: "25px" }}
              >
                Aplica sobre:
              </div>
              {requerimiento.apl.map((item, index) => (
                <div
                  className="item-apl"
                  key={index}
                  onClick={() => {
                    localStorage.setItem(
                      "comprobante-apl",
                      JSON.stringify(item.replace(/ /g, " - "))
                    );
                    window.open(window.location.href, "_blank");
                  }}
                >
                  {item.replace(/ /g, " - ")}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="container-r-item-details">
        <div className="container-r-info-p">
          <div className="label-req-title">Estado Actual: </div>
          <div className="r-list-item-details-clave-v">
            {requerimiento.state}
          </div>
          <div className="label-req-title">Responsable: </div>
          <div className="r-list-item-details-clave-v">
            {requerimiento.tDocAbmResponsibleName}
          </div>
          <div className="label-req-title">Solicitante: </div>
          <div className="r-list-item-details-title-v">
            {requerimiento.tDocAbmApplicantName}
          </div>
        </div>
        <div style={{ marginTop: "15px" }} className="container-r-info-p">
          <div className="label-req-title">Sector de Requerimiento: </div>
          <div className="r-list-item-details-clave-v">
            {requerimiento.tDocAbmReqSectorCodsem} (
            {requerimiento.tDocAbmReqSectorDescrp})
          </div>
          <div className="label-req-title">Depósito: </div>
          <div className="r-list-item-details-clave-v">
            {requerimiento.tDocAbmWarehouseDeposi} (
            {requerimiento.tDocAbmWarehouseDescrp})
          </div>
          <div className="label-req-title">Sector del Depósito: </div>
          <div className="r-list-item-details-title-v">
            {requerimiento.tDocAbmWarehouseSectorSector} (
            {requerimiento.tDocAbmWarehouseSectorDescrp})
          </div>
        </div>
        {requerimiento.tDocCodFor.includes("OC") ||
        requerimiento.tDocCodFor.includes("OCP") ? (
          <div>
            <div style={{ marginTop: "15px" }} className="container-r-info-p">
              <div className="label-req-title">Proveedor: </div>
              <div className="r-list-item-details-clave-v">
                {requerimiento.provider}
              </div>
              <div className="label-req-title">Condición de Pago: </div>
              <div className="r-list-item-details-clave-v">
                {requerimiento.paymentCondition}
              </div>
              <div className="label-req-title">Moneda de Emisión: </div>
              <div className="r-list-item-details-title-v">
                {requerimiento.emissionCoin}
              </div>
            </div>
            {requerimiento.originalReceiptStructure ||
            requerimiento.originalReceipt ? (
              <div style={{ marginTop: "15px" }} className="container-r-info-p">
                {requerimiento.originalReceiptStructure ? (
                  <>
                    <div className="label-req-title">
                      Estructura de Comprobante Original:
                    </div>
                    <div className="r-list-item-details-clave-v">
                      {requerimiento.originalReceiptStructure}
                    </div>
                  </>
                ) : null}
                {requerimiento.originalReceipt ? (
                  <>
                    <div className="label-req-title">
                      Comprobante Original:{" "}
                    </div>
                    <div className="r-list-item-details-title-v">
                      {requerimiento.originalReceipt}
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="container-r-item-title-details">Info Adicional</div>
        <div className="container-r-texto">
          <div className="r-list-item-details-data-v">
            {requerimiento.observ}
          </div>
        </div>
        <div className="container-r-item-title-details">Fecha</div>
        <div className="container-r-fecha">
          <div className="label-req-fecha">
            <div className="r-list-item-details-data-v">Alta </div>
            <div className="r-list-item-details-fecha-v">
              {requerimiento.tDocCodFor === "REQ"
                ? moment.utc(requerimiento.fecAlt).format("DD-MM-YYYY")
                : moment.utc(requerimiento.fecAltERP).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="label-req-fecha">
            <div className="r-list-item-details-data-v">Movimiento </div>
            <div className="r-list-item-details-fecha-v">
              {moment.utc(requerimiento.fchMov).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="label-req-fecha">
            <div className="r-list-item-details-data-v">Entrega </div>
            <div className="r-list-item-details-fecha-v">
              {moment.utc(requerimiento.deliveryDate).format("DD-MM-YYYY")}
            </div>
          </div>
          {requerimiento.state === "AUTORIZADO" && (
            <div className="label-req-fecha">
              <div className="r-list-item-details-data-v">Autorización </div>
              <div className="r-list-item-details-fecha-v">
                {moment.utc(requerimiento.fecAut).format("DD-MM-YYYY")}
              </div>
            </div>
          )}
        </div>
        {requerimiento.state === "AUTORIZADO" ? (
          <div style={{ paddingTop: "10px" }}>
            <div
              className="container-r-item-title-details"
              style={{ marginTop: "0px" }}
            >
              Observación de la Autorización
            </div>
            <div className="container-r-texto">
              <div className="r-list-item-details-data-v">
                {requerimiento.observAut}
              </div>
            </div>
          </div>
        ) : null}
        <div className="container-r-item-title-details">
          Items: {requerimiento.quantity}
        </div>
        <div className="container-list-products-r-gral">
          {products.map((product) => {
            return (
              <div className="container-list-products-r" key={product.codRed}>
                <div className="container-list-product-r-icon">
                  <div className="list-item-icon-r">
                    <FontAwesomeIcon icon={faBox} />
                  </div>
                  {product.codRed && (
                    <div className="list-item-title-r">{product.codRed}</div>
                  )}
                </div>
                <div className="container-list-products-r-details">
                  <div className="container-product-info-descrp">
                    <div>{product.descrp}</div>
                    <div>{product.textAdic}</div>
                  </div>
                  <div className="container-product-info">
                    <div className="container-cantidad-product-r">
                      <span class="text-decorator">Cantidad:</span>{" "}
                      {product.quantity}
                    </div>
                    {requerimiento.tDocCodFor.includes("OC") ||
                    requerimiento.tDocCodFor.includes("OCP") ? (
                      <>
                        {" "}
                        <div className="container-cantidad-product-r">
                          <span class="text-decorator">Precio:</span> ${" "}
                          {product.price}
                        </div>
                        {product.bonus1 && product.bonus1 !== 0 ? (
                          <div className="container-cantidad-product-r">
                            <span class="text-decorator">Bonificación1:</span> ${" "}
                            {product.bonus1}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {requerimiento.tDocCodFor.includes("OC") ||
                    requerimiento.tDocCodFor.includes("OCP") ? (
                      <div className="container-cantidad-product-r">
                        <span class="text-decorator">Total:</span> ${" "}
                        {product.bonus1 && product.bonus1 !== 0
                          ? product.quantity *
                            (product.price -
                              product.price * (-product.bonus1 / 100))
                          : product.quantity * product.price}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
          {requerimiento.tDocCodFor.includes("OC") ||
          requerimiento.tDocCodFor.includes("OCP") ? (
            <div className="r-products-total-without-taxes-container">
              <div className="r-products-total-without-taxes">
                Importe sin Impuestos:
              </div>
              <div style={{ fontWeight: "600" }}>$ {importeSinImpuestos}</div>
            </div>
          ) : null}
        </div>
        <div className="container-r-item-title-details">
          <div>Nota</div>
          {!editNota && (
            <div className="container-button-download">
              {!editNotaReservada && (
                <button className="button-edit" onClick={handleEditNota}>
                  <FontAwesomeIcon icon={faPen} />{" "}
                </button>
              )}
            </div>
          )}
          {editNota && (
            <div className="container-button-download">
              <button className="button-edit" onClick={handleEditNota}>
                <FontAwesomeIcon icon={faRectangleXmark} />
              </button>
              <button className="button-edit" onClick={saveChangesNota}>
                <FontAwesomeIcon icon={faFloppyDisk} />
              </button>
            </div>
          )}
        </div>
        <div className="container-r-texto">
          {!editNota && (
            <div className="r-list-item-details-data-v">
              {requerimiento.notePubl}
            </div>
          )}
          {editNota && (
            <textarea
              className="r-textarea-input"
              value={requerimiento.notePubl}
              onChange={(data) => handleModificaNota(data.target.value)}
            />
          )}
        </div>
        {usuario.userType.some((item) =>
          EDITAR_NOTAS_PRIVADAS.includes(item.typeName)
        ) && (
          <>
            <div className="container-r-item-title-details">
              <div>Nota Reservada</div>
              {!editNotaReservada && (
                <div className="container-button-download">
                  {!editNota && (
                    <button
                      className="button-edit"
                      onClick={handleEditNotaReservada}
                    >
                      <FontAwesomeIcon icon={faPen} />{" "}
                    </button>
                  )}
                </div>
              )}
              {editNotaReservada && (
                <div className="container-button-download">
                  <button
                    className="button-edit"
                    onClick={handleEditNotaReservada}
                  >
                    <FontAwesomeIcon icon={faRectangleXmark} />
                  </button>
                  <button
                    className="button-edit"
                    onClick={saveChangesNotaReservada}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                  </button>
                </div>
              )}
            </div>
            <div className="container-r-texto">
              {!editNotaReservada && (
                <div className="r-list-item-details-data-v">
                  {requerimiento.notePriv}
                </div>
              )}
              {editNotaReservada && (
                <textarea
                  className="r-textarea-input"
                  value={requerimiento.notePriv}
                  onChange={(data) =>
                    handleModificaNotaReservada(data.target.value)
                  }
                />
              )}
            </div>
          </>
        )}
        <div className="container-r-item-title-details">
          <div>Archivos</div>
          <div className="container-button-download">
            <button className="button-download" onClick={handleDownloadAll}>
              Descargar Todos <FontAwesomeIcon icon={faDownload} />{" "}
            </button>
          </div>
        </div>
        <div className="container-files-rq">
          <ul className="container-files-rq-details">
            {files.map((file) => (
              <li
                className="container-files-rq-details-items"
                key={file.name}
                onClick={() => abrirURL(file.url)}
              >
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="rq-container-buttons-bottom">
        <div
          className="rq-button-bottom button-pdf"
          onClick={() => generaPDF(requerimiento, "Admin")}
        >
          <FontAwesomeIcon icon={faFilePdf} /> PDF
        </div>
        {requerimiento.tDocCodFor == "REQ" ? (
          <div
            className="rq-button-bottom button-repetir"
            onClick={() => repetir(requerimiento, products)}
          >
            <FontAwesomeIcon icon={faRotateRight} /> Repetir
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ReqInfo;
