//Componentes Generales
import React, { useState, useEffect, useContext } from "react";
import NewProductItm from "../newProductItm/NewProductItm";
import SelectList from "../SelectList/SelectList";
import moment from "moment";

//Ruta para obtener sectores según depósito
import { getAbmWarehouseSectorsByDeposito } from "../../api/abmWarehouse.js";

//Contex de productos, solicitantes, responsables, sector de requerimientos y depósitos
import productsContext from "../../context/productos/productsContext";
import applicantsContext from "../../context/solicitantes/applicantsContext.js";
import responsiblesContext from "../../context/responsables/responsiblesContext.js";
import reqsSectorsContext from "../../context/sectoresReqs/reqsSectorsContext";
import warehousesContext from "../../context/depositos/warehousesContext";

//Tipos de Prioridades
import { PRIORITY, PRIORITYIN } from "../../utils/priority";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// Estilos
import "./FormReq.css";

// Constante con estructura del producto.
const estructura_producto = {
  tipPro: "",
  artCod: "",
  codRed: "",
  descrp: "",
  quantity: "1",
  textAdic: "",
  usr_id: (Math.random() * 100).toString(),
};

/*
    Componente encargado de mostrar el formulario de requerimiento.
        Donde edicion determina si mostrar algunas cosas y a que logica seguir en el guardado.
              req es la estructura del requerimiento.
              files son los archivos que almacena/ra el requerimiento.
              itemList son los items/productos del requerimeinto.
              guardarReq es la función del context que guarda el requerimiento.
              cerrar es la función encargada de ocultar el dialogo.
*/
function FormReq({
  edicion,
  req,
  reqSectorForTitleEdicion,
  files = [],
  itemList,
  guardarReq,
  cerrar,
}) {
  //State del requerimiento.
  const [requeriment, setRequeriment] = useState(req);
  //State del sector de requerimiento para agregar en el título del requerimiento.
  const [reqSectorForTitle, setReqSectorForTitle] = useState(
    reqSectorForTitleEdicion
  );
  //State de los sectores de depósito
  const [warehouseSectors, setWarehouseSectors] = useState([]);
  //State que determina si cambió la selección del depósito.
  const [warehouseChanged, setWarehouseChanged] = useState(false);
  //State que determina la muestra de las opciones de selección de estado.
  const [displayOptions, setDisplayOptions] = useState();
  //State de los items del requerimiento. El primero/ultimo es la constante definida al principio.
  const [items, setItems] = useState(
    itemList.length === 0
      ? [estructura_producto]
      : itemList.concat([estructura_producto])
  );
  //State de los archivos seleccionados.
  const [selectedFiles, setSelectedFiles] = useState(files);
  //State necesario para poder personalizar input de files.
  const [selectionColor, setSelectionColor] = useState(false);
  //State necesario para que el usuario no envie muchas veces el requerimiento cuando aprieta el boton muchas veces
  const [controlBotonEnviar, setControlBotonEnviar] = useState(false);

  //Context productos
  const pContext = useContext(productsContext);
  const { products, actualizarProducts } = pContext;

  //Context solicitantes
  const sContext = useContext(applicantsContext);
  const { applicants, actualizarApplicants } = sContext;

  //Context responsables
  const rContext = useContext(responsiblesContext);
  const { responsibles, actualizarResponsibles } = rContext;

  //Context sectores de requerimientos
  const sRContext = useContext(reqsSectorsContext);
  const { reqsSectors, actualizarReqsSectors } = sRContext;

  //Context depósitos con sus sectores
  const dContext = useContext(warehousesContext);
  const { warehouses, actualizarWarehouses } = dContext;

  /* 
        Disparador que actualiza la lista de productos, solicitantes, responsables, 
        sectores de requerimientos y depósitos con sus sectores disponibles 
        al momento de renderizar el componente.
    */
  useEffect(() => {
    const fn = async () => {
      await actualizarProducts();
      await actualizarApplicants();
      await actualizarResponsibles();
      await actualizarReqsSectors();
      await actualizarWarehouses();
    };
    fn();
  }, []);

  /*
        Manejador de requerimiento ante cambio de titulo.
            Donde data es el titulo ingresada por el usuario. 
        El parametro data es el valor con el que se setea el titulo del requerimiento.
    */
  const handleChangeTitle = (data) => {
    setRequeriment({ ...requeriment, title: data });
  };

  /*
        Manejador de requerimiento ante cambio de fecha Entrega.
            Donde data es la fecha Entrega ingresada por el usuario. 
        El parametro data es el valor con el que se setea la fecha Entrega del requerimiento.
    */
  const handleChangeDate = (data, dateReason) => {
    const fecha = new Date(data.concat("T15:00:00.000Z"));
    setRequeriment({ ...requeriment, [dateReason]: fecha });
  };

  /*
        Manejador de requerimiento ante cambio de observación.
            Donde data es la observación ingresada por el usuario. 
        El parametro data es el valor con el que se setea la observación del requerimiento.
    */
  const handleChangObservation = (data) => {
    setRequeriment({ ...requeriment, observ: data });
  };

  /*
        Manejador de requerimiento ante cambio de solicitante.
            Donde data es el solicitante ingresado por el usuario. 
        El parametro data es el valor con el que se setea el solicitante del requerimiento.
    */

  const handleChangeApplicant = (value) => {
    const solicitanteSeleccionado = applicants.find(
      (a) =>
        `${a.idSql}`.replace(/\s+/g, "") ===
        value.value.split(" ~ ")[0].replace(/\s+/g, "")
    );

    if (solicitanteSeleccionado !== undefined) {
      setRequeriment({
        ...requeriment,
        tDocAbmApplicantIdSql: solicitanteSeleccionado.idSql,
        tDocAbmApplicantName: solicitanteSeleccionado.name,
      });
    }
  };

  /*
        Manejador de requerimiento ante cambio de responsable.
            Donde data es el responsable ingresado por el usuario. 
        El parametro data es el valor con el que se setea el responsable del requerimiento.
    */

  const handleChangeResponsible = (value) => {
    const responsableSeleccionado = responsibles.find(
      (r) =>
        `${r.idSql}`.replace(/\s+/g, "") ===
        value.value.split(" ~ ")[0].replace(/\s+/g, "")
    );

    if (responsableSeleccionado !== undefined) {
      setRequeriment({
        ...requeriment,
        tDocAbmResponsibleIdSql: responsableSeleccionado.idSql,
        tDocAbmResponsibleName: responsableSeleccionado.name,
      });
    }
  };

  /*
        Manejador de requerimiento ante cambio del sector de requerimientos.
            Donde data es el sector de requerimientos ingresado por el usuario. 
        El parametro data es el valor con el que se setea el sector del requerimiento.
    */

  const handleChangeReqSector = (value) => {
    setReqSectorForTitle("");
    const sectorReqSeleccionado = reqsSectors.find(
      (rS) =>
        `${rS.codSem}`.replace(/\s+/g, "") ===
        value.value.split(" ~ ")[0].replace(/\s+/g, "")
    );

    if (sectorReqSeleccionado !== undefined) {
      setRequeriment({
        ...requeriment,
        tDocAbmReqSectorCodsem: sectorReqSeleccionado.codSem,
        tDocAbmReqSectorDescrp: sectorReqSeleccionado.descrp,
      });
      setReqSectorForTitle(
        sectorReqSeleccionado.codSem + " (" + sectorReqSeleccionado.descrp + ")"
      );
    }
  };

  /*
        Manejador de requerimiento ante cambio de depósito.
            Donde data es el depósito ingresado por el usuario. 
        El parámetro data es el valor con el que se setea el depósito.
    */

  const handleChangeWarehouse = async (value) => {
    // Primero, aseguramos que tDocAbmWarehouseDeposi se vacíe
    setRequeriment((prevRequeriment) => ({
      ...prevRequeriment,
      tDocAbmWarehouseDeposi: "",
    }));

    setWarehouseChanged(true);

    const depositoSeleccionado = warehouses.find(
      (w) =>
        `${w.deposi}`.replace(/\s+/g, "") ===
        value.value.split(" ~ ")[0].replace(/\s+/g, "")
    );

    if (depositoSeleccionado !== undefined) {
      setRequeriment((prevRequeriment) => ({
        ...prevRequeriment,
        tDocAbmWarehouseDeposi: depositoSeleccionado.deposi,
        tDocAbmWarehouseDescrp: depositoSeleccionado.descrp,
      }));

      const respWarehouseSectors = await getAbmWarehouseSectorsByDeposito(
        depositoSeleccionado.deposi
      );

      setWarehouseSectors(respWarehouseSectors.data.data);
    }
  };

  /*
        Manejador de requerimiento ante cambio de sectores de depósito.
            Donde data es el sector de depósito ingresado por el usuario. 
        El parámetro data es el valor con el que se setea el sector de depósito.
    */

  const handleChangeWarehouseSector = (value) => {
    setWarehouseChanged(false);
    const sectorDepositoSeleccionado = warehouseSectors.find(
      (wS) =>
        `${wS.sector}`.replace(/\s+/g, "") ===
        value.value.split(" ~ ")[0].replace(/\s+/g, "")
    );

    if (sectorDepositoSeleccionado !== undefined) {
      setRequeriment({
        ...requeriment,
        tDocAbmWarehouseSectorSector: sectorDepositoSeleccionado.sector,
        tDocAbmWarehouseSectorDescrp: sectorDepositoSeleccionado.descrp,
      });
    }
  };

  /* 
        Manejador encargado de agregar un item a los items del requerimiento.
            Donde data es el producto a añadir a los items.
        Se agrega el producto seleccionado y al final se agrega un item vacio (Constante estructura del producto).
    */
  const handleagregaItem = (data) => {
    //Se le asigna un id random al nuevo producto añadido. Esto es para facilitar la eliminación del item en caso de ser necesario.
    const tempData = { ...data, usr_id: (Math.random() * 100).toString() };
    //Se le asigna un id random al item vacio. Esto es para facilitar la eliminación del item en caso de ser necesario.
    const nuevaEstructura = {
      ...estructura_producto,
      usr_id: (Math.random() * 100).toString(),
    };
    //Logica para determinar donde inserta el item vacio.
    const temp_List = items.length === 1 ? [nuevaEstructura] : items;
    const newLista =
      temp_List.length === 1
        ? [tempData, ...temp_List]
        : [
            ...temp_List.filter((d) => d.artCod !== ""),
            tempData,
            nuevaEstructura,
          ];

    setItems(newLista);
  };

  /*
        Manejador encargado de eliminar un item de los items del requerimiento.
            Donde item es el item completo a eliminar.
            Donde i es la posición del item a eliminar.
        Setea los items despues de filtrar la posición indicada por parametro.
    */
  const handleDeleteConcept = (item, i) => {
    if (i !== items.length - 1) {
      setItems(
        items.filter((data, index) => {
          if (index !== i) {
            return data;
          }
        })
      );
    }
  };

  /* 
        Manejador ante cambio en los items.
            Donde data es el item modificado.
            Donde i es la posición del item modificado
        Setea los items despues de reemplazar  el item por el recibido por parametro.
    */
  const handleChangeItm = (data, i) => {
    const newLista = items.map((it, index) => {
      return it.codRed === data.codRed &&
        it.artCod === data.artCod &&
        index === i
        ? data
        : it;
    });
    setItems(newLista);
  };

  /*
        Manejador ante selección de una prioridad.
            Donde priorIndex es el codigo identificativo de la prioridad.
        Al setear la prioridad en el requerimiento, se oculta el desplegable de opciones de prioridades disponibles.
    */
  const seleccionarPriority = (priorIndex) => {
    displayOpciones();
    setRequeriment({ ...requeriment, priority: priorIndex });
  };

  /* 
        Determina si se muestra o no el display de opciones de prioridades.
    */
  const displayOpciones = () => {
    displayOptions ? setDisplayOptions(false) : setDisplayOptions(true);
  };

  /* 
        Manejador encargado de agregar archivos al requerimiento.
            Donde e es el archivo subido por el usuario.
    */
  const handleFileInputChange = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };

  /*
        Guarda el requerimiento mediante función del context.
        Distintos comportamientos segun variable edición.
    */
  const handleSaveRequirement = async () => {
    setControlBotonEnviar(true); // Bloqueo el botón mientras se esta enviando el requerimiento.
    const newLista = items.filter((d) => d.artCod !== "");
    if (edicion === "Edicion") {
      await guardarReq(
        requeriment,
        reqSectorForTitle,
        newLista,
        selectedFiles,
        files
      );
      setControlBotonEnviar(false); // Habilitar el botón nuevamente
      cerrar();
    } else {
      let resp = await guardarReq(
        requeriment,
        reqSectorForTitle,
        newLista,
        selectedFiles
      );
      if (resp) {
        setRequeriment({ ...req });
        setItems([estructura_producto]);
        setSelectedFiles([])
      }
      setControlBotonEnviar(false); // Habilitar el botón nuevamente
    }
  };

  /* 
        Manejador encargado de eliminar archivos al requerimiento.
            Donde aborrar es el archivo borrado por el usuario.
    */
  const eliminaFile = (aborrar) => {
    const array = Array.from(selectedFiles);
    setSelectedFiles(array.filter((data) => data.name !== aborrar.name));
  };

  /*
        Función necesaria para poder personalizar input de files.
    */
  const cambioColorSeleccion = () => {
    setSelectionColor(!selectionColor);
  };

  return (
    <div className="container-gr-addreq">
      <div className="container-r-list-item-header">
        <input
          placeholder="Ingrese un título..."
          className="list-item-header-title-addReq"
          value={requeriment.title}
          onChange={(data) => handleChangeTitle(data.target.value)}
          autoFocus={true}
          style={{ fontWeight: "bold" }}
          maxLength={60}
        ></input>
        <div className="container-prioritys-gral">
          <div
            className={
              requeriment.priority === 2
                ? "r-list-item-header-priority-urgente  margin-right-r"
                : requeriment.priority === 1
                ? "r-list-item-header-priority-alta  margin-right-r"
                : "r-list-item-header-priority-media  margin-right-r"
            }
            onClick={displayOpciones}
          >
            {PRIORITY[requeriment.priority ? requeriment.priority : 0]}
          </div>
          {displayOptions && (
            <div className="options-container-fr  margin-right-r">
              <div className="options-container-fr-back">
                {PRIORITY.map((prior) => {
                  return (
                    <div
                      key={prior}
                      className={
                        PRIORITYIN[prior] === 2
                          ? "r-list-item-header-priority-urgente"
                          : PRIORITYIN[prior] === 1
                          ? "r-list-item-header-priority-alta"
                          : "r-list-item-header-priority-media"
                      }
                      value={prior}
                      onClick={() => seleccionarPriority(PRIORITYIN[prior])}
                    >
                      {prior}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {edicion && (
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={faXmark}
            onClick={() => cerrar(null)}
          />
        )}
      </div>
      <div className="container-AddReq-item-gral">
        <div className="container-AddReq-item-details">
          <div className="container-r-item-title-details">Fecha de Entrega</div>
          <input
            type="date"
            className="label-AddReq-fecha"
            value={moment.utc(requeriment.deliveryDate).format("YYYY-MM-DD")}
            onChange={(data) =>
              handleChangeDate(data.target.value, "deliveryDate")
            }
          />
        </div>
        <div
          className="container-AddReq-item-details"
          style={{ marginLeft: "40px" }}
        >
          <div className="container-r-item-title-details">
            Fecha de Movimiento
          </div>
          <input
            type="date"
            className="label-AddReq-fecha"
            value={moment.utc(requeriment.fchMov).format("YYYY-MM-DD")}
            onChange={(data) => handleChangeDate(data.target.value, "fchMov")}
          />
        </div>
      </div>
      <div className="container-AddReq-item-gral">
        <div className="container-AddReq-item-details-applicant">
          <div
            className="r-list-item-details-data-v"
            style={{ marginRight: "20px" }}
          >
            <SelectList
              text={"Solicitante"}
              options={applicants.map((rU) => {
                return { value: `${rU.idSql}`, label: `${rU.name}` };
              })}
              handleChange={handleChangeApplicant}
              property={"Solicitante"}
              valueAux={{
                label: requeriment.tDocAbmApplicantName,
                value: requeriment.tDocAbmApplicantIdSql,
              }}
              emptyInputs={[]}
            />
          </div>
        </div>
        <div className="container-AddReq-item-details-responsible">
          <div
            className="r-list-item-details-data-v"
            style={{ marginLeft: "20px" }}
          >
            <SelectList
              text={"Responsable de Área"}
              options={responsibles.map((r) => {
                return { value: `${r.idSql}`, label: `${r.name}` };
              })}
              handleChange={handleChangeResponsible}
              property={"Responsable de Área"}
              valueAux={{
                label: requeriment.tDocAbmResponsibleName,
                value: requeriment.tDocAbmResponsibleIdSql,
              }}
              emptyInputs={[]}
            />
          </div>
        </div>
      </div>
      <div className="container-AddReq-item-details-text">
        <SelectList
          text={"Sector de Requerimientos"}
          options={reqsSectors.map((rS) => {
            return {
              value: `${rS.codSem}`,
              label: `${rS.codSem}  (${rS.descrp})`,
            };
          })}
          handleChange={handleChangeReqSector}
          property={"Sector de Requerimientos"}
          valueAux={{
            label:
              requeriment.tDocAbmReqSectorCodsem &&
              requeriment.tDocAbmReqSectorDescrp
                ? `${requeriment.tDocAbmReqSectorCodsem} (${requeriment.tDocAbmReqSectorDescrp})`
                : "",
            value: requeriment.tDocAbmReqSectorCodsem || "",
          }}
          emptyInputs={[]}
        />
      </div>
      <div className="container-AddReq-item-gral">
        <div
          style={{ marginRight: "5px" }}
          className="container-AddReq-item-details-text-warehouse"
        >
          <SelectList
            text={"Depósito"}
            options={warehouses.map((w) => {
              return {
                value: `${w.deposi}`,
                label: `${w.deposi}  (${w.descrp})`,
              };
            })}
            handleChange={handleChangeWarehouse}
            property={"Depósitos"}
            valueAux={{
              label:
                requeriment.tDocAbmWarehouseDeposi &&
                requeriment.tDocAbmWarehouseDescrp
                  ? `${requeriment.tDocAbmWarehouseDeposi} (${requeriment.tDocAbmWarehouseDescrp})`
                  : "",
              value: requeriment.tDocAbmWarehouseDeposi || "",
            }}
            emptyInputs={[]}
          />
        </div>
        <div
          style={{ marginLeft: "40px" }}
          className="container-AddReq-item-details-text-warehouse"
        >
          <SelectList
            text={"Sector de Depósito"}
            options={warehouseSectors.map((wS) => {
              return {
                value: `${wS.sector}`,
                label: `${wS.sector}  (${wS.descrp})`,
              };
            })}
            handleChange={handleChangeWarehouseSector}
            property={"Sectores de Depósito"}
            valueAux={{
              label:
                requeriment.tDocAbmWarehouseSectorSector &&
                requeriment.tDocAbmWarehouseSectorDescrp &&
                warehouseChanged === false
                  ? `${requeriment.tDocAbmWarehouseSectorSector} (${requeriment.tDocAbmWarehouseSectorDescrp})`
                  : "",
              value: requeriment.tDocAbmWarehouseSectorSector || "",
            }}
            emptyInputs={[]}
          />
        </div>
      </div>
      <div className="container-AddReq-item-details-text">
        <div className="container-r-item-title-details">Texto Adicional</div>
        <div className="container-input-texto">
          <div className="r-text-adic">
            <textarea
              placeholder="Ingrese texto adicional"
              className="r-item-text-input"
              style={{ fontWeight: 600 }}
              value={requeriment.observ}
              onChange={(data) => handleChangObservation(data.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="container-AddReq-item-details-items">
        <div className="container-r-item-title-details">
          Items: {items.length === 0 ? 0 : items.length - 1}
        </div>
        <div className="container-items-products-rq-gral">
          {items.length > 0 &&
            items.map((c, index) => {
              return (
                <NewProductItm
                  data={c}
                  index={index}
                  key={c.usr_id}
                  productsList={products}
                  agregaItem={(data) => handleagregaItem(data)}
                  modificaItm={(data, indice) => handleChangeItm(data, indice)}
                  borraItem={() => handleDeleteConcept(c, index)}
                />
              );
            })}
        </div>
      </div>
      <div>
        <div className="container-button-rq-save-new2">
          <div
            className={
              selectionColor
                ? "button-rq-save-new2-selected"
                : "button-rq-save-new2"
            }
          >
            <div className="file-select" id="src-file1">
              <input
                onFocus={cambioColorSeleccion}
                onBlur={cambioColorSeleccion}
                type="file"
                name="src-file1"
                aria-label="Agregar Archivo"
                multiple
                onChange={handleFileInputChange}
              />
            </div>
          </div>
        </div>
        <div className="container-new-rq-files">
          <ul className="container-new-rq-files-details">
            {selectedFiles.map((file) => (
              <li key={file.name}>
                {file.name}
                <FontAwesomeIcon
                  onClick={() => eliminaFile(file)}
                  style={{
                    cursor: "pointer",
                    color: "red",
                    marginLeft: "20px",
                  }}
                  icon={faXmark}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="container-button-rq-save-new">
        <button
          className="button-rq-save-new"
          disabled={controlBotonEnviar}
          onClick={handleSaveRequirement}
        >
          Finalizar
        </button>
      </div>
    </div>
  );
}

export default FormReq;
